import {Toast, Dialog} from 'vant';

const utils = {

  /*格式化时间*/
  formatDate(time, fmt) {
    try {
      var time = time || '';
      if (typeof time == 'string') {
        time = time.replace(/-/g, '/')
      }
      if (!time) {
        return ''
      }
      let date = new Date(time);
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
      }
      let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
      };
      for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          let str = o[k] + '';
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
        }
      }
      return fmt;
    } catch (e) {
      return ''
    }

    function padLeftZero(str) {
      return ('00' + str).substr(str.length);
    }
  },

  rTime(date) {
    var json_date = new Date(date).toJSON();
    return new Date(new Date(json_date) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') 
  },

  toast(message,callback) {
    Toast({
      message: message,
      duration: 1500,
      onClose:function () {
        callback&&callback()
      }
    });
  },

  /*非空验证*/
	verifyEmpty(v, message){
		if (typeof(v) == "string") {
      v = v.replace(/\s/g, '');
    }
    if (!v) {
      utils.toast(message);
      return false;
    }

    return true;
	},

  /*验证手机号格式*/
  verifyMobile(mobile){
    if (mobile.length != 11) {
      utils.toast('请输入11位手机号码');

      return false;
    }

    if (!/^1[3-9]\d{9}$/.test(mobile)) {
      utils.toast('该手机号码不正确，请重新输入');
      return false;
    }

    return true;

  },
};


export default utils