import axios from 'axios'
import Vue from 'vue'
import store from '../store'
import router from '../router'
import qs from 'qs'
import { Toast, Loading } from 'vant'
//axios.defaults.withCredentials=true;
// 添加请求拦截器
axios.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  // console.log(config, store.state.openData)

  // config.headers.common['Authorization'] = '67c766a9542142a6b677b8563829f163';
  config.headers.common['Authorization'] = localStorage.getItem('token');
  config.headers.common['token'] = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOlsiNjEwMDM1MjkiLCIzMDAwMDAiLCJp5rex5ZyzIl0sImlhdCI6MTY2NTYyMzg5Nn0.Rin-sGovq8px3Xzz6FmJbwYkm-a_0quqcmD0jznF0oo'


  // if (config.url.indexOf('/homepage') > -1 || config.url.indexOf('/orderFood') > -1 ) {
  //   config.headers.common['Applet-Token'] = store.state.token;
  //   config.headers.common['Session-ID'] = store.state.sessionID;
  // }
  // //通知  删除请求头
  // if (config.url.indexOf('/systemNotification') > -1 || config.url.indexOf('/appletlogin') > -1 || config.url.indexOf('/wx') > -1) {
  //   delete config.headers.common['Session-ID'];
  // }
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  // 文件流
  Toast.clear()
  if (response.data.code === undefined) {
    return response
  }
  
  if (response.data.code != 0 && response.data.code != 40029) {
    // if (response.data.code === 201 && (router.currentRoute && router.currentRoute.path !== '/')
    //  && (router.currentRoute && router.currentRoute.path !== '/login')) {
    //   router.push('/login')
    //   Toast.fail(response.data.message);
    //  }
    //  if (response.data.code == 400) {
    //   return response;
    //  } else if (response.data.code !== 201) {
    //   Toast.fail(response.data.message);
    //  }
    if (response.data.code !== 0) {
      Toast.fail(response.data.message);
    }
    Toast.fail(response.data.msg || '异常返回');
    // return Promise.reject(new Error(response.data.msg || '异常返回'));
    return Promise.reject(response.data);
  } else {
    return response;
  }
}, function (error) {
  // 对响应错误做点什么
  Toast.fail("网络响应失败");
  return Promise.reject(error);
});

class BaseService {
  constructor () {
    this.getThis = () => this
  }


  static get (url, params, options) {
    let config = {}
    if (params) {
      url += '?' + qs.stringify(params)
    }
    if (options) {
      config = Object.assign(config, options)
    }
    return axios.get(url, config).then(response => {
      return response
    })
  }

  static post (url, data, config) {
    return axios.post(url, data, config).then(response => {
      return response
    })
  }

  static put (url, data, options) {
    let config = options || {}
    return axios.put(url, data, config).then(response => {
      return response
    })
  }

  static patch (url, data) {
    return axios.patch(url, data).then(response => {
      return response
    })
  }

  static del (url, params, options) {
    let config = options || {}
    if (params) {
      url += '?' + qs.stringify(params)
    }
    return axios.delete(url, config).then(response => {
      return response
    })
  }

  static downLoadFile (fileName, options) {
    options.method = options.method || 'get'
    options.responseType = 'blob'
    if (options.params) {
      options.url += '?' + utils.httpQueryString(options.params)
      delete options.params
    }
    return axios(options).then(response => {
      const blob = new Blob([response], {
        type: response['type']
      })
      let url = URL.createObjectURL(blob)
      if (window.navigator.msSaveBlob) {
        // ie 浏览器
        try {
          window.navigator.msSaveBlob(blob, fileName)
        } catch (e) {
          // console.log(e)
        }
      } else {
        // 谷歌浏览器 创建a标签 添加download属性下载
        let a = document.createElement('a')
        a.target = '_blank'
        a.href = url
        a.download = fileName
        a.click()
      }
      return { code: 200, message: '下载成功' }
    })
  }

  static uploadFiles (url, obj, options) {
    // files [{ name: 名称, value: 文件或值 }]
    if (!obj) {
      return false
    }
    let data = new FormData()
    for (let key in obj) {
      data.set(key, obj[key])
    }
    options = Object.assign({
      headers: { 'content-type': 'multipart/form-data' }
    }, options || {})
    // if(showLoading) {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0
      });
    // }
    return axios.post(url, data, options).then(response => {
      return response
    })
  }
}

export default BaseService