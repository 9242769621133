<template>
  <div class="base-tabs">
    <div
      v-for="(item, index) in tabsList"
      :key="item.value"
      :class="[{on: selectedTab == index}, 'item']"
      @click="changeTab(index, item.value)">
      {{ item.label }}
    </div>
    <div
      class="tab-line"
      :style="{
        transform: 'translateX('+ tabLine +'px) translateX(-50%)',
        transitionDuration: '0.3s'
      }">
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseTabs",
  props: {
    tabsList: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      selectedTab: 0,
      tabLine: 0
    };
  },
  methods: {
    changeTab(index, value) {
      this.selectedTab = index;
      this.tabLineLocation();
      this.$emit('changeTab', value)
    },
    tabLineLocation() {
      let cw = document.body.clientWidth,
          length = this.tabsList.length,
          locatoin = cw / length;
      this.tabLine = (this.selectedTab + 1) * locatoin - locatoin / 2;
    }
  },
  mounted() {
    this.tabLineLocation()
  }
};
</script>

<style lang="less" scoped>
.base-tabs {
  background: #ffffff;
  display: flex;
  justify-content: space-around;
  position: relative;
  border-bottom: 1px solid #F0F0F0;
  .item {
    text-align: center;
    padding: 12px 0;
    &.on {
      color: #01673F;
    }
  }
  .tab-line {
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 40px;
    height: 2px;
    background: #01673F;
  }
}
</style>
