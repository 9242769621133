import baseService from './baseService';

function get (url, params, options) { return baseService.get(url, params, options) };
function post (url, params, options) { return baseService.post(url, params, options) };
function put (url, data, options) { return baseService.put(url, data, options) };
function patch (url, data) { return baseService.patch(url, data) };
function del (url, options) { return baseService.del(url, options) };
function uploadFiles (url, params, options) { return baseService.uploadFiles(url, params, options) };

/*********************************/

// juncheng：192.168.81.70:90
// 47服务器：47.106.248.243:9092
const wsUri = code => {
  if (process.env.NODE_ENV == 'development') {
    return `ws://yikatong.demo.ywqian.com:9092/ykt/websocket/${code}/`
  } else {
    return `ws://ykt.baiyunairport.com/ykt/websocket/${code}/`
   // return `ws://www.vongfeng.com:8080/ykt/websocket/${code}/`
   //return `ws://192.168.31.222:18080/ykt/websocket/${code}/`  //测试
  }
} ; // websocket请求地址



// 登录
const wechatLogin = params => get('/wechat/myPage', params) //微信登录

// i深圳登录
const getInitCode = params => post('/third/isz/getInitCode', params);
const getUserInfo = params => post('/third/isz/getUserInfo', params);
const getPersonalInfo = params => get('/platform/social/user', params); //获取用户信息

// 上传接口
const upload = params => uploadFiles('/public/fdfs/upload', params);

// 投诉举报
const addRepport = params => post('/report', params); //新增投诉举报
const repportList = params => get('/report/selectReport', params); //投诉举报记录
const reportProgress = params => get('/report/getReportingProgress', params); //投诉举进度
const reportDetail = params => get('/report/getReportDetails', params); //投诉举报详情
const reportDetailPic = params => get('/report/getReportPicture', params); //投诉举报详情图片

// 公众消防活动预约
const getSelect = params => get('/report/getSelect', params);  //新增预约获取单位类别
const saveFireTrain = params => post('/fireFightingActiities/saveFireTrain', params); //消防安全培训预约
const fireTrainRecord = params => post('/fireFightingActiities/getRecord', params); //消防安全培训历史记录
const fireTrainRecordDetail = params => get('/fireFightingActiities/getFireTraningDetails', params); //消防安全培训历史记录详情
const firePrevent = params => post('/firePrevention/saveFirePrevent', params); //火灾防控指导预约
const firePreventRecord = params => post('/firePrevention/getRecord', params); //火灾防控指导历史记录
const firePreventRecordDetail = params => get('/firePrevention/getFirePreventionDetails', params); //火灾防控指导历史记录详情
const LargeScale = params => post('/largeScaleActivities/saveLargeScale', params); //大型活动安保预约
const LargeScaleRecord = params => post('/largeScaleActivities/getRecord', params); //大型活动安保历史记录
const LargeScaleRecordDetail = params => get('/largeScaleActivities/getLargeScaleDetails', params); //大型活动安保历史记录详情
const ReviewAdvance = params => post('/reviewAdvance/saveReviewAdvance', params); //提前复查预约
const ReviewAdvanceRecord = params => post('/reviewAdvance/getRecord', params); //提前复查历史记录
const ReviewAdvanceRecordDetail = params => get('/reviewAdvance/getReviewAdvanceDetails', params); //提前复查历史记录详情
const SupervisionInspection = params => post('/supervision/saveSupervisionInspection', params); //消防监督检查预约
const SupervisionInspectionRecord = params => post('/supervision/getRecord', params); //消防监督检查历史记录
const SupervisionInspectionRecordDetail = params => get('/supervision/getSupervisionDetails', params); //消防监督检查历史记录详情
const getQueryAllCompany = params => post('/societyCompany/appIndexPub', params); //社会单位库列表
const saveRegistration = params => post('/aqjc/xfgzcr/registration', params); //告知承诺保存基本信息

// 学习平台
const getMostAnswerCategory = params => post('/question/category/getMostAnswerCategory', params); //获取最多答题的5种分类
const getAllCategory = params => get('/question/category/getByName', params); //获取全部题库分类
const getQuestionRandom = params => get('/question/random', params); //随机20道指定类型的题目
const pushRecord = params => post('/record', params); //提交答题
const getCategory = params => get('/question/category', params); //获取全部题库分类
const studyHistory = params => get('/record/viewHistory', params); //查看历史记录
const studyViewDetail = params => get('/record/viewDetail', params); //查看答题明细

// 消防资讯
const getPartSpecialDetail = params => get('/propaganda/special/getPartSpecialDetail', params); //获取发布的专题和图文说明
const getSpecialDetailById = params => get('/propaganda/special/detail/getSpecialDetailById', params); //根据专题id获取专题详情
const getPropaganda = params => get('/propaganda/data/detail', params); //根据id获取稿件内容

// 个人中心
const fixSocialUser = params => put('/platform/social/user', params); //修改用户信息
const getAllUnitByName = params => get('/platform/unit/getAllUnitByName', params); //创建单位页面接口
const getPlatFormUnitByName = params => get('/platform/unit/getPlatFormUnitByName', params); //加入单位页面接口
const createFormUnit = params => post('/platform/unit', params); //新增单位

// 获取省市区
const getPartArea = params => get('/tw/area/getPartArea', params); 

// 智能问答
const getMostClickQuestion = params => get('/questionAnswerStorage/getMostClickQuestion', params); //获取5条猜你想问
const getQuestionAnswer = params => get('/questionAnswerStorage/getQuestionAnswer', params); //迷糊查询问答
const addLeaveWord = params => post('/questionAnswer/leaveWord', params); //新增留言
const getHistoryLeaveWord = params => post('/questionAnswer/leaveWord/getHistoryLeaveWord', params); //历史留言
const startRecord = params => post('/questionAnswer/record', params); //开始问答
const finishRecord = params => post('/questionAnswer/record/finishQuestionAnswer', params); //结束问答
const getQARecord = params => post('/questionAnswer/record/detail', params); //添加记录详情


export default {
  wsUri,

  wechatLogin,
  getInitCode,
  getUserInfo,
  upload,
  addRepport,
  repportList,
  reportProgress,
  reportDetail,
  reportDetailPic,
  getSelect,
  saveFireTrain,
  fireTrainRecord,
  fireTrainRecordDetail,
  firePrevent,
  firePreventRecord,
  firePreventRecordDetail,
  LargeScale,
  LargeScaleRecord,
  LargeScaleRecordDetail,
  ReviewAdvance,
  ReviewAdvanceRecord,
  ReviewAdvanceRecordDetail,
  SupervisionInspection,
  SupervisionInspectionRecord,
  SupervisionInspectionRecordDetail,
  getQueryAllCompany,
	saveRegistration,
  getMostAnswerCategory,
  getAllCategory,
  getQuestionRandom,
  pushRecord,
  getCategory,
  studyHistory,
  studyViewDetail,
  getPartSpecialDetail,
  getSpecialDetailById,
  getPropaganda,
  fixSocialUser,
  getAllUnitByName,
  getPlatFormUnitByName,
  createFormUnit,
  getPartArea,
  getMostClickQuestion,
  getQuestionAnswer,
  addLeaveWord,
  getHistoryLeaveWord,
  startRecord,
  finishRecord,
  getQARecord,
  getPersonalInfo
}