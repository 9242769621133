import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
import router from '@/router'
import Cache from '@/services/cache'

Vue.use(Vuex)

function initState() {
  return {
    user: {},
    personInfo: {}, //个人信息
    token: '',
    sessionID: '',
    changePassType: '',
    messageList: [],
    dialogMessageList: [],
    dialogMessageIndex: 0,
    openData: null,
    openId: '',
    rechargeData: null,
    mapaddress: '', // 地图位置
    mapcenter: '', // 地图经纬度
    mapadcode: '', //区编号code
    reportInfo: {}, //投诉举报信息
  }
}

export default new Vuex.Store({
  state: () => {
    const oldState = Cache.get('state', true);
    if (oldState && oldState.sessionID) {
      axios.defaults.headers.common['Session-ID'] = oldState.sessionID;
    }
    return oldState || initState()
  },
  mutations: {
    setToken:(state,data)=>{
      state.token = data;
    },
    setSessionID:(state,data)=>{
      state.sessionID = data;
      axios.defaults.headers.common['Session-ID'] = data;
    },
    setUser:(state, user) => {
      state.user = user;
    },
    setPersonInfo (state,params) {
      state.personInfo = params || {};
    },
    logout(state) {
      const oldState = initState()
      for (const key in oldState) {
        state[key] = oldState[key]
      }
      axios.defaults.headers.common['Session-ID'] = '';
      router.push('/login');
    },
    setChangePassType:(state, type) => {
      state.changePassType = type
    },
    setMessageList:(state, data) => {
      state.messageList = data
    },
    setDialogMessageList:(state, data) => {
      state.dialogMessageList = data
    },
    setDialogMessageIndex:(state, val) => {
      state.dialogMessageIndex = val
    },
    setOpenData:(state, data) => {
      state.openData = data;
    },
    setOpenId:(state, data) => {
      state.openId = data;
    },
    setRechargeData:(state, data) => {
      state.rechargeData = data;
    },
    setMapaddress: (state, data) => {
      state.mapaddress = data;
    },
    setMapcenter: (state, data) => {
      state.mapcenter = data;
    },
    setMapadcode: (state, data) => {
      state.mapadcode = data;
    },
    setReportInfo: (state, data) => {
      state.reportInfo = data;
    }
  },
  actions: {
  },
  modules: {
  }
})
