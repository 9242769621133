import Vue from 'vue'
import 'vant/lib/index.css' // vant样式
import {
  Button,
  Calendar,
  Empty,
  Toast,
  Dialog,
  Field,
  Form,
  Picker,
  Popup,
  NoticeBar,
  NavBar,
  PullRefresh,
  List,
  Loading,
  DropdownMenu,
  DropdownItem,
  ImagePreview,
  Tabs,
  Tab,
  Uploader,
  Icon,
  Image as VanImage,
  ActionSheet,
  DatetimePicker,
  RadioGroup,
  Radio,
  CheckboxGroup,
  Checkbox,
} from 'vant'

Vue.use(Button);
Vue.use(Calendar);
Vue.use(Empty);
Vue.use(Toast);
Vue.use(Dialog);
Vue.use(Field);
Vue.use(Form);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(NoticeBar);
Vue.use(NavBar);
Vue.use(PullRefresh);
Vue.use(List);
Vue.use(Loading);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(ImagePreview);
Vue.use(Tabs);
Vue.use(Tab);
Vue.use(Uploader);
Vue.use(Icon);
Vue.use(VanImage);
Vue.use(ActionSheet);
Vue.use(DatetimePicker);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(CheckboxGroup);
Vue.use(Checkbox);