<template>
  <div id="app" :class="{'app-padTop': false}"
    :style="{backgroundColor: meta.bg || 'rgba(254, 248, 250, 0.39)'}"
  >
    <!-- :class="{'app-padTop':!meta.topHide}" -->
    <!--  :class="{'app-padTop': false}" -->

    <!-- <div class="top-nav-bar" v-if="!meta.topHide"> -->
    <div class="top-nav-bar" v-if="false">
      <van-nav-bar v-if="!meta.backHide" :title="meta.name" @click-left="$router.back()">
        <i class="icon back-arrow" slot="left"></i>
      </van-nav-bar>
      <van-nav-bar v-else :title="meta.name"></van-nav-bar>
    </div>

    <keep-alive>
      <router-view v-if="meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!meta.keepAlive" />

    <!-- <van-tabbar route v-if="meta.showTabbar" active-color="#2f77ed"> -->
    <van-tabbar route v-if="showTabbar" active-color="#2f77ed">
      <van-tabbar-item v-for="(item,index) in tabbar" replace :to="item.url" :key="index" :badge="item.name=='购物车'&&personInfo.cartNum?personInfo.cartNum:'' || item.name=='信息'&&personInfo.allMessageNum?personInfo.allMessageNum:''">
        <span>{{item.name}}</span>
        <template #icon="props">
          <img :src="props.active ? item.active : item.inactive"/>
        </template>
      </van-tabbar-item>
    </van-tabbar>
    <div v-if="meta.showTabbar" class="tabbar-placehold"></div>
    <!-- 要缓存时显示组件的 router-view-->
    <!-- <keep-alive>
      <router-view v-if="meta.isAlive" ></router-view>
    </keep-alive>
    <router-view v-if="!meta.isAlive" ></router-view> -->
  </div>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex';
  import {Tabbar, TabbarItem} from 'vant';

export default {
  name: 'app',
  components: {
    'van-tabbar': Tabbar,
    'van-tabbar-item': TabbarItem
  },
  data() {
    return {
      websock: null,
      allMessageList: [],
      wsMessageList: [],
      overdueMessageList: [],
      allDialogMessageList: [],
      wsDialogMessageList: [],
      overdueDialogMessageList: [],
      timer: null,
      meta: {
        topHide: '',
        backHide: '',
        whiteArrow: '',
        name: '',
        bg: '',
        showTabbar: '',
      },
      showTabbar: false,
      tabbar: [
          {
            url: '/home',
            name: '首页',
            inactive: require('@/assets/images/icon_tabber_01.png'),
            active: require('@/assets/images/icon_tabber_02.png'),
            hasLogin: false,
          },
          {
            url: '/personalIndex',
            name: '个人中心',
            inactive: require('@/assets/images/icon_tabber_05.png'),
            active: require('@/assets/images/icon_tabber_06.png'),
            hasLogin: true,
          },
        ],
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    // i深圳登录
    this.loginIshenzhen()
  },

  updated() {
    setTimeout(() => {
      this.meta = this.$route.meta;
      // console.log('meta', this.meta)
    }, 50)
  },

  watch: {
    $route(nv) {
      // console.log('nv', nv)
      if(nv.name === 'home' || nv.name === 'personalIndex') {
        this.showTabbar = true
      } else {
        this.showTabbar = false
      }
    }
  },
  methods: {
    loginIshenzhen() {
      // localStorage.setItem('token', 'oUPu95BrorvXQKTM-FCJUsM835I4')
      this.$Http.getInitCode().then(codeRes => {
        let res = codeRes.data
				if (res.code === 0) {
					sc.config({
						debug: false, // 是否开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来
						appId: res.data.appId, // 在统一 APP 开放平台服务器申请的 appId
						initCode: res.data.initCode,
						nativeApis: ['userAuth']
					})
					
					sc.ready(() => {
						sc.userAuth({
							appId: res.data.appId
						}, res => {
							/*
							 sc.userAuth会首先判断用户是否登录，若没有登录，则会主动调起登录窗口，无需在此调用 isLogin 和 login 接口
							 */
							if (res.code === 0) {
                localStorage.setItem('token', res.data.openId);
                localStorage.setItem('requestCode', res.data.requestCode);
								// uni.setStorageSync('token', res.data.openId)
								//    用户同意授权
								let requestCode = res.data.requestCode
								let openId = res.data.openId
								// let openId = '67c766a9542142a6b677b8563829f163'
								this.$Http.getUserInfo({
                  requestCode,
                  openId,
								}).then(info => {
									console.log('info',info)
                  let personalInfo = info.data.data || {}
                  this.$store.commit('setPersonInfo', personalInfo)
								})
							} else {
								/*
										用户拒绝授权或其它失败情况
										code: -1 默认失败
										code: -10001    没有初始化JSSDK
										code: -10002    用户点击拒绝授权
							   code: -10003    用户未登录
										*/
								console.log(res)
                sc.goback();
								// uni.showModal({
								// 	content: res.message
								// })
							}
						})
					})
					sc.error((err) => {
						console.log('错误', err)
					})
				}
			})
    },
  },
  created() {
  }
}
</script>

<style lang="less">
#app {
  height: 100%;
  overflow-y: auto;
  color: #333333;
	font-size: 14px;
  &.app-padTop {
    padding-top: 48px;
  }

  .top-nav-bar {
    width: 100%;
    height: 0;
    .van-nav-bar {
      width: 100%;
      height: 48px;
      background-color: #930504;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 999;
      &:after {
        border: none;
      }
      .van-nav-bar__title {
        font-size: 18px;
        color: #222222;
        font-weight: bold;
        line-height: 48px;
        color: #fff;
      }
      .van-nav-bar__left {
        left: 0;
      }
      .van-nav-bar__right {
        right: 0;
      }
      .van-nav-bar__left, .van-nav-bar__right {
        height: 48px;
        padding: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 999;
      }
      .back-arrow {
        width: 22px;
        height: 22px;
        background-image: url("./assets/images/back-white.png");
        background-size: contain;
        // &.white {
        //   background-image: url("assets/img/icon_arrow_01.png");
        // }
      }
    }
    &.has-shadow {
      .van-nav-bar {
        box-shadow: 0 3px 10px 0 rgba(31, 136, 201, 0.1);
      }
    }
  }

  // 切换栏样式更改
  .van-tabs{
    display: flex;
    flex-direction: column;
    height: 100%;
    .van-tabs__content{
      flex: 1;
      overflow-y: auto;
    }
  }
  .van-tabs--line .van-tabs__wrap {
    border-bottom: 1px solid rgba(0, 0, 0, 0.39);
  }
}

/*单行省略*/
  .text-overflow {
    white-space: nowrap;
    word-break: keep-all;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  /*多行省略*/
  .multi-line-omit {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* autoprefixer: off*/
    -webkit-box-orient: vertical;
    /* autoprefixer: on*/
  }
</style>
