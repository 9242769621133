import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import 'amfe-flexible/index' // 弹性布局
import './assets/css/index.less' // 公共样式文件
import './utils/vant' // 引入vantUI库
import _ from 'lodash' //第三方库
import GL_Component from '@/components/index' // 全局组件
import BaseHeader from '@/components/BaseHeader' // 公共头部

import utils from '@/utils/utils'
import service from '@/services'
import Cache from '@/services/cache'
import AMap from 'vue-amap'
import { Toast } from 'vant'
//import wx from "weixin-jsapi" //扫描用

Vue.use( _ );
Vue.use(GL_Component, BaseHeader);
Vue.use(AMap);

Vue.prototype.$utils = utils;
Vue.prototype.$Http = service
Vue.prototype.$Cache = Cache
Vue.prototype.$axios = axios
//Vue.prototype.wx = wx

Vue.config.productionTip = false
window._AMapSecurityConfig = {
  securityJsCode: '2a86391a25d15829855fdbaa17fb3f7d', 
}

AMap.initAMapApiLoader({
  key: 'ceb14b701fb56311bc005266b3fd5323', // 郑玎web服务
  // key: '8422b50eae48d88ac2071638611a6c6e', // 艺湘
  // v: '1.4.4',
  // 插件集合 （插件按需引入）
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.ToolBar', 'AMap.MapType','AMap.Geocoder','AMap.Geolocation'],
   // 默认高德 sdk 版本为 1.4.4
   v: '1.4.4'
});


//登录拦截
router.beforeEach((to, from, next) => {
  // console.log(to.meta, store.state.openId)
  // console.log(localStorage.getItem('token'))
  let token = localStorage.getItem('token')
  // if(to.meta.isAuth && !store.state.openId){
    if(to.meta.isAuth && !token){
    Toast({
      duration: 200,
      message: '未登录，请先登录'
    })
    next({
      path: '/login', // 未登录则跳转至login页面
      // query: {redirect: to.fullPath || ''} // 登陆成功后回到当前页面，这里传值给login页面，to.fullPath为当前点击的页面
    })
  } else {
    next()
  }
})


function getHttpHost () {
  return new Promise((resolve) => {
    axios.get('/config.json').then(res => {
      if (process.env.NODE_ENV == 'development') {
        axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
      } else {
        axios.defaults.baseURL = res.data.target; // axios响应拦截无code多了一层
      }
      resolve();
    });
  });
}

getHttpHost().then(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app');
});
