<template>
  <div :class="[styleClass, 'base-header']">
    <button class="back" v-if="isBack" @click="goBack"></button>
    <div class="title">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: "BaseHeader",
  data() {
    return {
      title: '',
      isBack: true,
      styleClass: ''
    };
  },
  watch: {
    $route: {
      handler: function(val) {
				console.log(111,val)
        this.title = val.meta.name;
        this.isBack = val.meta.isBack;
        this.styleClass = val.meta.styleClass;
      },
      // 深度观察监听
      deep: true,
      immediate: true
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="less" scoped>
.base-header {
  position: relative;
  text-align: center;
  padding: 10px 0;
  // border-bottom: 1px solid #F0F0F0;
  background: #930504;
  .back {
    position: absolute;
    left: 15px;
    top: 11px;
    width: 22px;
    height: 22px;
    border: none;
    background: url(../assets/images/back-white.png) no-repeat center center/22px 22px;
  }
  .title {
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
  }
  &.payment-header {
    border-bottom: 1px solid #01673F;
    background: #01673F;
    /deep/ .title {
      color: #ffffff;
    }
    /deep/ .back {
      background: url(../assets/images/back-white.png) no-repeat center center/22px 22px;
    }
  }
  &.bill-header {
    border-bottom: 1px solid transparent;
  }
}
</style>
