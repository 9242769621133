<template>
  <div class="base-notification">
    <van-notice-bar
      class="notice-swipe"
      scrollable
      left-icon="volume-o"
      v-if="$store.state.messageList.length > 0">
      <span
        v-for="(item, index) in $store.state.messageList"
        :key="index + item.message">
        <template v-if="item.noticeDisplayStyle != 0">
          <span>{{index + 1}}.</span>
          <span v-html="item.message" style="margin-right: 20px;"></span>
        </template>
      </span>
    </van-notice-bar>
    <!-- 弹窗信息 -->
    <van-dialog v-model="isDialog" title="信息" :beforeClose="onSubmit">
      <div
        class="notification-content"
        v-if="$store.state.dialogMessageList[$store.state.dialogMessageIndex]"
        v-html="$store.state.dialogMessageList[$store.state.dialogMessageIndex].message"></div>
    </van-dialog>
  </div>
</template>

<script>
export default {
  name: "BaseNotification",
  data() {
    return {
      isDialog: false,
    };
  },
  computed: {
    dialogMessageList() {
      return this.$store.state.dialogMessageList;
    }
  },
  watch: {
    // dialogMessageList(newValue, oldValue) {
    //   if (newValue.length > this.$store.state.dialogMessageIndex) {
    //     this.isDialog = true
    //   }
    // }
    dialogMessageList: {
      handler(newValue, oldValue) {
        if (newValue.length > this.$store.state.dialogMessageIndex) {
          this.isDialog = true
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    onSubmit(action, done) {
      let length = this.$store.state.dialogMessageList.length - 1,
          index = this.$store.state.dialogMessageIndex;
      this.$store.commit('setDialogMessageIndex', index + 1)
      if (length > index) {
        done(false)
      } else {
        done()
      }
    }
  },
  created() {
  }
};
</script>

<style lang="less" scoped>
.notice-swipe {
  /deep/ p {
    display: inline-block;
  }
}
.notification-content {
  padding: 20px;
  line-height: 1.5;
}
</style>
