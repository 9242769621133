import Vue from 'vue'
import VueRouter from 'vue-router'
import Cache from '@/services/cache'
import store from '../store'

// 解决Vue-Router升级导致的Uncaught(in promise) navigation guard问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    // redirect: 'home',
    meta: { name: '首页', isBack: false, isAuth: false, backHide: true, showTabbar: true },
    component: () => import(/* webpackChunkName: "home" */ '../views/index.vue'),
    // children: [
    //   {// 首页
    //     path: '/home',
    //     name: 'home',
    //     meta: { name: '智慧一卡通', isBack: false, isAuth: false },
    //     component: () => import(/* webpackChunkName: "home" */ '../views/home/index.vue')
    //   }
    // ]
  },
  {
    path: '/login',
    name: 'login',
    meta: { name: '登录', isBack: true, isAuth: false },
    component: () => import(/* webpackChunkName: "externalExplain" */ '../views/login.vue')
  },
  // 预约模块
  {
    path: '/bookingIndex',
    name: 'bookingIndex',
    meta: { name: '新增预约', isBack: true, isAuth: false },
    component: () => import(/* webpackChunkName: "externalExplain" */ '../views/bookingModel/index.vue')
  },
  {
    path: '/addbooking',
    name: 'addBooking',
    meta: { name: '新增预约', isBack: true, isAuth: false, keepAlive: true },
    component: () => import(/* webpackChunkName: "externalExplain" */ '../views/bookingModel/addBooking.vue')
  },
  {
    path: '/selectUnitName',
    name: 'selectUnitName',
    meta: { name: '选择单位名称', isBack: true, isAuth: false },
    component: () => import(/* webpackChunkName: "externalExplain" */ '../views/bookingModel/selectUnitName.vue')
  },
  {
    path: '/history',
    name: 'history',
    meta: { name: '历史记录', isBack: true, isAuth: false },
    component: () => import(/* webpackChunkName: "externalExplain" */ '../views/bookingModel/history.vue')
  },
  {
    path: '/historyDetail',
    name: 'historyDetail',
    meta: { name: '历史记录详情', isBack: true, isAuth: false },
    component: () => import(/* webpackChunkName: "externalExplain" */ '../views/bookingModel/historyDetail.vue')
  },
  {
    path: '/toView',
    name: 'toView',
    meta: { name: '预览查看', isBack: true, isAuth: false },
    component: () => import(/* webpackChunkName: "externalExplain" */ '../views/bookingModel/toView.vue')
  },
  // 火灾隐患举报模块
  {
    path: '/firereport',
    name: 'firereport',
    meta: { name: '深圳消防隐患举报', isBack: true, isAuth: false },
    component: () => import(/* webpackChunkName: "externalExplain" */ '../views/fireReportModel/index.vue')
  },
  {
    path: '/record',
    name: 'record',
    meta: { name: '消防隐患举报记录', isBack: true, isAuth: false, isAlive: true },
    component: () => import(/* webpackChunkName: "externalExplain" */ '../views/fireReportModel/record.vue')
  },
  {
    path: '/recordDetail',
    name: 'recordDetail',
    meta: { name: '消防隐患举报记录详情', isBack: true, isAuth: false },
    component: () => import(/* webpackChunkName: "externalExplain" */ '../views/fireReportModel/recordDetail.vue')
  },
	{
	  path: '/businessIndex',
	  name: 'businessIndex',
	  meta: { name: '营业前消防安全检查预约', isBack: true, isAuth: false },
	  component: () => import(/* webpackChunkName: "externalExplain" */ '../views/business/safeDocument.vue')
	},
	{
	  path: '/informInletIndex',
	  name: 'informInletIndex',
	  meta: { name: '营业前消防安全检查预约（告知承诺件）', isBack: true, isAuth: false },
	  component: () => import(/* webpackChunkName: "externalExplain" */ '../views/business/informInlet.vue')
	},
	{
	  path: '/notInformInletIndex',
	  name: 'notInformInletIndex',
	  meta: { name: '营业前消防安全检查预约（非告知承诺件）', isBack: true, isAuth: false },
	  component: () => import(/* webpackChunkName: "externalExplain" */ '../views/business/notInformInlet.vue')
	},
	{
	  path: '/informCommitIndex',
	  name: 'informCommitIndex',
	  meta: { name: '告知承诺书', isBack: true, isAuth: false },
	  component: () => import(/* webpackChunkName: "externalExplain" */ '../views/business/informCommit.vue')
	},
	{
	  path: '/safetyCommitmentIndex',
	  name: 'safetyCommitmentIndex',
	  meta: { name: '消防安全承诺', isBack: true, isAuth: false },
	  component: () => import(/* webpackChunkName: "externalExplain" */ '../views/business/safetyCommitment.vue')
	},
	{
	  path: '/basicInfoIndex',
	  name: 'basicInfoIndex',
	  meta: { name: '基本信息登记表', isBack: true, isAuth: false },
	  component: () => import(/* webpackChunkName: "externalExplain" */ '../views/business/basicInfo.vue')
	},
  {
    path: '/instruction',
    name: 'instruction',
    meta: { name: '深圳消防隐患举报', isBack: true, isAuth: false },
    component: () => import(/* webpackChunkName: "externalExplain" */ '../views/fireReportModel/instruction.vue')
  },
  {
    path: '/reportForm',
    name: 'reportForm',
    meta: { name: '深圳消防隐患举报', isBack: true, isAuth: false },
    component: () => import(/* webpackChunkName: "externalExplain" */ '../views/fireReportModel/reportForm.vue')
  },
  {
    path: '/mapSelect',
    name: 'mapSelect',
    meta: { name: '选择场所位置', isBack: true, isAuth: false },
    component: () => import(/* webpackChunkName: "externalExplain" */ '../views/fireReportModel/mapSelect.vue')
  },
  // 智能问答
  {
    path: '/message',
    name: 'message',
    meta: { name: '消防智能问答', isBack: true, isAuth: false, keepAlive: true },
    component: () => import(/* webpackChunkName: "externalExplain" */ '../views/QAModel/message.vue')
  },
  {
    path: '/leaveMessage',
    name: 'leaveMessage',
    meta: { name: '留言咨询', isBack: true, isAuth: false },
    component: () => import(/* webpackChunkName: "externalExplain" */ '../views/QAModel/leaveMessage.vue')
  },
  // 学习平台
  {
    path: '/studyIndex',
    name: 'studyIndex',
    meta: { name: '学习中心', isBack: true, isAuth: false },
    component: () => import(/* webpackChunkName: "externalExplain" */ '../views/studyModel/index.vue')
  },
  {
    path: '/studyList',
    name: 'studyList',
    meta: { name: '学习答题', isBack: true, isAuth: false },
    component: () => import(/* webpackChunkName: "externalExplain" */ '../views/studyModel/list.vue')
  },
  {
    path: '/studyAnswer',
    name: 'studyAnswer',
    meta: { name: '分类知识', isBack: true, isAuth: false },
    component: () => import(/* webpackChunkName: "externalExplain" */ '../views/studyModel/answer.vue')
  },
  {
    path: '/studySheet',
    name: 'studySheet',
    meta: { name: '试题', isBack: true, isAuth: false },
    component: () => import(/* webpackChunkName: "externalExplain" */ '../views/studyModel/sheet.vue')
  },
  {
    path: '/studyHistory',
    name: 'studyHistory',
    meta: { name: '答题历史', isBack: true, isAuth: false },
    component: () => import(/* webpackChunkName: "externalExplain" */ '../views/studyModel/history.vue')
  },
  {
    path: '/studyHistoryDetail',
    name: 'studyHistoryDetail',
    meta: { name: '答题详情', isBack: true, isAuth: false },
    component: () => import(/* webpackChunkName: "externalExplain" */ '../views/studyModel/historyDetail.vue')
  },
  // 个人中心
  {
    path: '/personalIndex',
    name: 'personalIndex',
    meta: { name: '个人中心', isBack: false,  isAuth: false, backHide: true, showTabbar: true },
    component: () => import(/* webpackChunkName: "externalExplain" */ '../views/personalModel/index.vue')
  },
  {
    path: '/createUnit',
    name: 'createUnit',
    meta: { name: '创建单位', isBack: true, isAuth: false },
    component: () => import(/* webpackChunkName: "externalExplain" */ '../views/personalModel/createUnit.vue')
  },
  {
    path: '/joinUnit',
    name: 'joinUnit',
    meta: { name: '加入单位', isBack: true, isAuth: false },
    component: () => import(/* webpackChunkName: "externalExplain" */ '../views/personalModel/joinUnit.vue')
  },
  {
    path: '/info',
    name: 'info',
    meta: { name: '个人信息', isBack: true, isAuth: false },
    component: () => import(/* webpackChunkName: "externalExplain" */ '../views/personalModel/info.vue')
  },
  {
    path: '/fireInfoModelIndex',
    name: 'fireInfoModelIndex',
    meta: { name: '消防资讯', isBack: true, isAuth: false },
    component: () => import(/* webpackChunkName: "externalExplain" */ '../views/fireInfoModel/index.vue')
  },
  {
    path: '/fireInfoModelDetail',
    name: 'fireInfoModelDetail',
    meta: { name: '资讯详情', isBack: true, isAuth: false },
    component: () => import(/* webpackChunkName: "externalExplain" */ '../views/fireInfoModel/detail.vue')
  },
  {
    path: '/fireInfoModelThemeList',
    name: 'fireInfoModelThemeList',
    meta: { name: '资讯分类列表', isBack: true, isAuth: false },
    component: () => import(/* webpackChunkName: "externalExplain" */ '../views/fireInfoModel/themeList.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // scrollBehavior(to, from, savePosition) {
  //   if (savePosition) {
  //           //解决页面从列表页跳转到详情页返回,初始在原来位置
  //     return savePosition;
  //   } else {
  //           //解决页面跳转后页面高度和前一个页面高度一样
  //     return { x: 0, y: 0 };
  //   }
  // }
})

router.beforeEach((to, from, next) => {
  // let token = store.state.user;
  // if (to.meta.isAuth) {
  //   if (Object.keys(token).length > 0) {
  //     if ((to.path=='/order/myOrder' && to.path != location.pathname)) {
  //       location.assign(to.fullPath)
  //     } else {
  //       next()
  //     }
  //   } else {
  //     next('/login')
  //   }
  // } else {
  //   next()
  // }
  next()
})

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
  if (to.meta.name) {
    document.title = to.meta.name
  }
})

export default router
